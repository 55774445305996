import React, { Component } from "react"
import './About.css'

class About extends Component {
    render() {
        return (
            <React.Fragment>
                <div id='about'>
                    <div className='header'>
                        <div className='inner'>
                            <h3>Innovation in interpersonal communications</h3>
                        </div>
                    </div>
                    <p>Verbal Edge Incorporated provides workshops designed to help professionals thrive within
                        challenging work environments. Our approach incorporates the characteristics
                        of <a href='#' target='_blank'>Emotional Intelligence (EQ)</a> and the latest in interpersonal communication strategies in
                        order to empower professionals to respond to any verbal encounter with skill and grace.
                        Most important of all, all of our workshops emphasize the relevance of these skills, not only
                        professionally, but personally as well.
                    </p>
                </div>

                <div id='services'>
                    <div className='header'>
                        <div className='inner'>
                            <h3>Verbal Edge offers a number of services in order to help clients to target specific needs:</h3>
                        </div>
                    </div>
                    <ul>
                        <li className='container'>
                            <div className='left'>
                                <img src={'core-logo.png'} alt='C.O.R.E. Logo' />
                                <p>Conflict doesn’t have to be a bad thing. In fact, with the proper
                                    skills, conflict can represent tremendous opportunities for
                                    positive change and growth.
                                </p>
                            </div>
                            <div className='right'>
                                <p>
                                    C.O.R.E De-escalation focuses on skills
                                    designed to help professionals to transcend
                                    negative emotions while enabling the
                                    performance of positive emotions designed
                                    to preserve and even strengthen
                                    relationships. C.O.R.E De-escalation is
                                    offered for:
                                </p>
                                <ul>
                                    <li>Frontline Professionals</li>
                                    <li>Law Enforcement Professionals</li>
                                </ul>
                            </div>
                        </li>
                        <li className='container'>
                            <div className='left'>
                                <img src={'core-leadership.png'} alt='C.O.R.E. Leadership' />
                                <p>
                                    In many instances, the skills that we promote for are not always
                                    the skills that make great leaders. While hard work and
                                    dedication to duty are admirable qualities for frontline staff,
                                    great leaders require skills that inspire and motivate others.
                                    Traits such as consistency, empathy, respect and
                                    trustworthiness separate great leaders from good leaders.
                                </p>
                            </div>
                            <div className='right'>
                                <p>
                                    C.O.R.E Leadership defines the traits
                                    necessary to be a great leader and provides
                                    the skills necessary to perform these traits
                                    effectively and consistently. We offer a two
                                    part leadership curriculum:
                                </p>
                                <ul>
                                    <li>Fundamentals of great leadership I</li>
                                    <li>Fundamentals of great leadership II</li>
                                </ul>
                            </div>
                        </li>
                        <li className='container'>
                            <div className='left'>
                                <img src={'core-customer-experience.png'} alt='C.O.R.E. Customer Experience' />
                                <p>
                                    In the online market where customers can compare products
                                    and pricing at the click of a button, the only real separation can
                                    come down to customer service.
                                </p>
                            </div>
                            <div className='right'>
                                <p>
                                    C.O.R.E De-escalation focuses on skills
                                    designed to transcend negative emotions,
                                    while enabling the performance of positive
                                    behaviors designed to preserve and even
                                    strengthen relationships. C.O.R.E De-
                                    escalation is offered for:
                                </p>
                                <ul>
                                    <li>Frontline Professionals</li>
                                    <li>Law Enforcement Professionals</li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>

                <div id='bottom-contact-div'>
                    <button id='bottom-contact-button' onClick={this.props.toggleContactModal}>Contact Us</button>
                </div>
            </React.Fragment>
        )
    }
}

export default About
