import React, { Component } from "react";
import './Contact.css'


function isValidEmail(email) {
    if (!email || email === '' || email === undefined || email === null) {
        return 'This field is required.'
    }
    let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}')
    let emailValid = regex.test(email)
    if (emailValid === true) {
        return true
    }
    return 'The email address provided is invalid.'
}

function isValidPhoneNumber(phone) {
    if (!phone || phone === '' || phone === undefined || phone === null) {
        return true
    }
    let regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    let phoneValid = regex.test(phone)
    if (phoneValid === true) {
        return true
    }
    return 'The phone number provided is invalid.'
}

function isValidName(name) {
    if (!(!name || name === '' || name === undefined || name === null)) {
        return true
    }
    return 'This field is required.'
}

function isValidMessage(message) {
    if (!message || message === '' || message === undefined || message === null) {
        return 'This field is required.'
    }
    if (message.length > 10) {
        return true
    }
    return 'Please provide a longer message.'
}

class Contact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: {
                first_name: {
                    value: '',
                    valid: undefined,
                    errorMessage: '',
                },
                last_name: {
                    value: '',
                    valid: undefined,
                    errorMessage: '',
                },
                organization: {
                    value: '',
                    valid: undefined,
                    errorMessage: '',
                },
                email: {
                    value: '',
                    valid: undefined,
                    errorMessage: '',
                },
                phone: {
                    value: '',
                    valid: undefined,
                    errorMessage: '',
                },
                message: {
                    value: '',
                    valid: undefined,
                    errorMessage: '',
                },
            }
        }
    }
    addLead = async (e) => {
        e.preventDefault()

        const addLeadUrl = 'http://api.verbaledge.ca/leads/add/'
        const payload = {
            first_name: document.getElementById('first-name').value,
            last_name: document.getElementById('last-name').value,
            organization: document.getElementById('organization').value,
            email: document.getElementById('email').value,
            phone: document.getElementById('phone').value,
            message: document.getElementById('message').value,
        }

        let valid = true
        const validation = {
            first_name: isValidName,
            last_name: isValidName,
            email: isValidEmail,
            phone: isValidPhoneNumber,
            message: isValidMessage,
        }
        let formData = this.state.formData
        for (const [key, validator] of Object.entries(validation)){
            const fieldID = key.replace('_', '-')
            const field = document.getElementById(fieldID)

            const errorFieldID = `${fieldID}-error`
            const errorField = document.getElementById(errorFieldID)

            const value = payload[key]
            const response = validator(value)
            if (response !== true) {
                field.classList.add('error')
                errorField.classList.add('error')
                valid = false
                formData[key].valid = false
                formData[key].errorMessage = response
            } else {
                field.classList.remove('error')
                errorField.classList.remove('error')
                formData[key].valid = true
                formData[key].errorMessage = ''
            }
        }
        this.setState({ formData: formData})

        if (valid === true) {
            await fetch(addLeadUrl, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            }).then(() => {
                this.props.toggleContactModal()
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className='modal closed' id='contact-modal'>
                    <div className='modal-inner'>
                        <button className='close-modal button' onClick={this.props.toggleContactModal}></button>
                        <h3 id='contact-title'>Contact Us</h3>
                        <form id='contact-form'>
                            <input id='first-name' placeholder='First Name' required />
                            <p id='first-name-error'>{this.state.formData.first_name.errorMessage}</p>
                            <input id='last-name' placeholder='Last Name' required />
                            <p id='last-name-error'>{this.state.formData.last_name.errorMessage}</p>
                            <input id='organization' placeholder='Organization' />
                            <p id='organization-error'>{this.state.formData.organization.errorMessage}</p>
                            <input id='email' placeholder='Email Address' type='email' required />
                            <p id='email-error'>{this.state.formData.email.errorMessage}</p>
                            <input id='phone' placeholder='Phone Number' />
                            <p id='phone-error'>{this.state.formData.phone.errorMessage}</p>
                            <textarea id='message' placeholder='What can we do for you?' required></textarea>
                            <p id='message-error'>{this.state.formData.message.errorMessage}</p>
                            <button onClick={this.addLead}>Submit</button>
                        </form>
                    </div>
                </div>
                <div className='modal-overlay closed' id='contact-modal-overlay' onClick={this.props.toggleContactModal}></div>
            </React.Fragment>
        )
    }
}

export default Contact
