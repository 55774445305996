import React, { Component } from "react";
import './Header.css'

class Header extends Component {

    render() {
        return (
            <header>
                <img id='header-logo' src={'verbal-edge-logo.png'} />
                <nav role='navigation'>
                    <ul>
                        <li><a href='#'>About Us</a></li>
                        <li><a href='#'>Training</a></li>
                        <li><button onClick={this.props.toggleContactModal}>Contact Us</button></li>
                    </ul>
                </nav>
            </header>
        )
    }
}

export default Header