import React, { Component } from "react"
import './Featurebox.css'

const foo = {
    title: "Maximize Your Potential",
    tagline: "Helping professionals to be their best when it matters most.",
    animationDuration: "30s",
    adjectives: [
        {name: "Respect",
         delay: "0s",
         top: "20%",
         left: "40%"},
        {name: "Self Awareness",
         delay: "3s",
         top: "60%",
         left: "10%"},
        {name: "Empowerment",
         delay: "6s",
         top: "50%",
         left: "50%"},
        {name: "Credibility",
         delay: "9s",
         top: "5%",
         left: "16%"},
        {name: "Dignity",
         delay: "12s",
         top: "66%",
         left: "70%"},
        {name: "Empathy",
         delay: "15s",
         top: "33%",
         left: "33%"},
        {name: "Ethics",
         delay: "18s",
         top: "10%",
         left: "66%"},
        {name: "Self Control",
         delay: "21s",
         top: "10%",
         left: "10%"},
        {name: "Professionalism",
         delay: "24s",
         top: "25%",
         left: "50%"},
        {name: "Harmony",
         delay: "27s",
         top: "75%",
         left: "80%"}
    ]
}

class Featurebox extends Component {
    makeAdj(adj) {
        let style = {animationDuration: foo.animationDuration, animationDelay: adj.delay, top: adj.top, left: adj.left}
        return <h3 key={adj.name} style={style}>{adj.name}</h3>
    }

    render() {
        return (
            <div id='feature'>
                <img id='banner' src={'banner.jpg'} alt='Mountain Banner' />
{/*                <div id='adjectives'>
//                   {foo.adjectives.map(adj => this.makeAdj(adj))}
//              </div>
//               <div id='leaf'></div>
//                <div id='headline'>
//                    <div id='title'>{foo.title}</div>
//                    <div id='tagline'>{foo.tagline}</div>
//                </div> */}
            </div>
        )
    }
}

export default Featurebox;
