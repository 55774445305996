import logo from './logo.svg';
import './App.css';
import About from "./components/About";
import Contact from "./components/Contact";
import Featurebox from "./components/Featurebox";
import Header from "./components/Header";

function App() {
    function toggleContactModal() {
        const modal = document.querySelector('#contact-modal')
        const modalOverlay = document.querySelector('#contact-modal-overlay')

        modal.classList.toggle('closed')
        modalOverlay.classList.toggle('closed')

        if (!modal.classList.contains('closed')) {
            document.querySelector('body').scrollIntoView()
        }
    }

    return (
        <main>
            <Header toggleContactModal={toggleContactModal} />
            <Featurebox />
            <About toggleContactModal={toggleContactModal} />
            <Contact toggleContactModal={toggleContactModal} />
        </main>
    );
}

export default App;
